import React from "react";
import footer from "../assets/png/liftrocketFooter.png";
import { navigate } from "gatsby";
import logo from "../assets/png/liftrocketCalculator2.png";

export const CalLogo = () => {
  return (
    <div className="calculatorLogo">
    <img src={logo} alt="liftrocket logo" />
  </div>
  );
};
export const CalFooter = () => {
    

    return (
        <div className="calculatorFooter">
        <div className="calculatorFooterImg">
        <img  src={footer} alt="liftrocket footer" />

        </div>
       
        <a className="calculatorFooterPP" 
       target="_blank" href={"/privacypolicy"}>
            Privacy Policy</a>
        <div className="calculatorFooterCR">All rights reserved. LiftRocket, LLC.</div>

      </div>
    );
  };
  


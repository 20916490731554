import React from "react";
import { Link } from "gatsby";
import { config } from "../components";
import logoStandard from "../assets/svg/liftRocketLogo.svg";
import facebook from "../assets/svg/facebook-footer.svg";
import twitter from "../assets/svg/twitter-footer.svg";
import instagram from "../assets/svg/instagram-footer2.svg";
import footerDesktop from "../assets/svg/footerDesktop.svg";
import footerMobile from "../assets/svg/footerMobile.svg";
export const FooterOld = () => {
  return (
    <div className="container">
      <div className="footer">
        <div className="columns">
          <div className="column">
            <div className="columns">
              <Link className="column footerItem" to={"/ourstory"}>
                Our Story
              </Link>
              <Link className="column footerItem" to={"/ourteam"}>
                Our Team
              </Link>
              <Link
                className="column footerItem"
                to={"/liftrocketcommunityfund"}
              >
                LiftRocket Community Fund
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Footer2 = () => {
  return (
    <>
      {/*  <div className="mobileIconContainer liftRocketBody">
        <div className="mobileIcon">
          <a href="https://apps.apple.com/app/liftrocket/id1497592160">
            <img
              src={appleMobile}
              alt="apple mobile icon"
              className="image storeMobileIcon"
            />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.liftrocket.liftrocket">
            <img
              src={googleMobile}
              alt="google mobile icon"
              className="image storeMobileIcon"
            />
          </a>
        </div>
      </div>
      */}

      <div className="liftRocketBody">
        <div className="footer footerFont">
          <div className="columns">
            <div className="column">
              <div className="columns">
                <Link to="/" className="column footerItemImage">
                  <img
                    src={logoStandard}
                    alt="header rocket"
                    width="120"
                    height="120"
                  />
                </Link>
                <div className="columns is-mobile is-hidden-tablet">
                  <div className="column footerItem is-half-mobile">
                    <div className="flexCol">
                      <div className="footerTitle">FOLLOW US</div>
                      <a href="https://www.facebook.com/liftrockett/">
                        Facebook
                      </a>
                      <a href="https://twitter.com/liftrocket?lang=en">
                        Twitter
                      </a>
                      <a href="https://www.instagram.com/liftrocket/">
                        Instagram
                      </a>
                    </div>
                  </div>

                  <div className="column footerItem is-half-mobile">
                    <div className="flexCol">
                      <div className="footerTitle">LEARN MORE</div>
                      
                      <a href={"/?section=about"}>How It Works</a>
                      <Link to={"/faq"}>FAQ</Link>
                      {/* <Link to={"/blog"}>Blog</Link> */}
                      <Link to={"/privacypolicy"}>Privacy</Link>
                      <Link to={"/termsofuse"}>Terms</Link>
                    </div>
                  </div>
                </div>
                
                <div className="column footerItem is-hidden-mobile">
                  <div className="flexCol">
                    <div className="footerTitle">LINKS</div>
                      <Link to={"/faq"}>FAQs</Link>
                      {/* <Link to={"/"}>How we help</Link> */}
                      {/* <Link to={"/blog"}>Blog</Link> */}
                      <Link to={"/termsofuse"}>Terms & Conditions</Link>
                      <Link to={"/privacypolicy"}>Privacy</Link>
                  </div>
                </div>
                <div className="column footerItem is-hidden-mobile">
                  <div className="flexCol">
                    <div className="footerTitle">FOLLOW US</div>
                    <a href="https://www.facebook.com/liftrockett/">Facebook</a>
                    <a href="https://twitter.com/liftrocket?lang=en">Twitter</a>
                    <a href="https://www.instagram.com/liftrocket/">
                      Instagram
                    </a>
                  </div>
                </div>
                <div className="column footerItem">
                  <div className="flexCol">
                    <div className="footerTitle">CONTACT US</div>
                    <a href="mailto:member.services@liftrocket.com">
                      <div>memberservices@</div>
                      <div>liftrocket.com</div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const Footer = () => {
  return (
    <>
      <div className="has-background-primary">
        <div className="footer footerFont">
          <div className="columns">
            <div className="column is-one-quarter">
              <div className="">
                <div className="footerImageContainer">
                  <Link to="/" className="column footerItemImage">
                    <img
                      src={logoStandard}
                      className="footerLogo"
                      alt="header rocket"
                    />
                  </Link>
                  <div className="footerSocialContainer">
                    <a href="https://www.facebook.com/liftrockett/" className="footerSocial">
                      <img
                        src={facebook}
                        alt="facebook"
                      />
                    </a>
                    <a href="https://twitter.com/liftrocket?lang=en" className="footerSocial">
                      <img
                        src={twitter}
                        alt="twitter"
                      />
                    </a>
                    <a href="https://www.instagram.com/liftrocket/" className="footerSocial">
                      <img
                        src={instagram}
                        alt="instagram"
                      />
                    </a>
                  </div>

                </div>
               
              </div>

            </div>
            <div className="column is-three-quarter">
              <div className="shortWhiteHorizontalLine2Container"><div className="shortWhiteHorizontalLine2"></div></div>
              
              
              <div className="footerContent">
                  <div className="footerContentItem">
                    <div className="flexCol">
                      <div className="shortWhiteHorizontalLine"></div>
                      <a id="footerLink" href={`${config.frontendUrl}/requestLift/1`}>REQUEST A LIFT</a>
                      <Link id="footerLink" to={"/ourstory"}>ABOUT US</Link>
                      <Link id="footerLink" to={"/faq"}>FAQS</Link>
                      {/* <Link to={"/blog"}>BLOGS</Link> */}
                    </div>
                  </div>
                  <div className="footerContentItem">
                    <div className="flexCol">
                    <div className="shortWhiteHorizontalLine"></div>

                      <Link id="footerLink" to={"/termsofuse"}>TERMS {"&"} CONDITIONS</Link>
                      <Link id="footerLink" to={"/privacypolicy"}>PRIVACY POLICY</Link>
                      
                      <a id="footerLink" href="mailto:member.services@liftrocket.com">CONTACT US</a>
                      
                    </div>
                  </div>
                  

              
                
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </>
  );
};

export const SportFooter = () => <div className="sportFooterBox">
  <div className="sportFooter">
    <img
      src={footerDesktop}
      className="desktopFlex"
      alt="header rocket"
    />
    <img
      src={footerMobile}
      className="mobileFlex"
      alt="header rocket"
    />

  </div>
  <div className="sportFooterText">
    <div>© LiftRocket, LLC. </div>
    <Link to={"/privacypolicy"}><div>Privacy Policy</div></Link>
    
  </div>
  
</div>
export const config = {
  backendAPi: process.env.GATSBY_BACKEND_API||"http://localhost:5000/api/v1",
  frontendUrl: process.env.GATSBY_FRONTEND_URL||"http://localhost:3000",
  liftRocketSupportEmail: "member.services@liftrocket.com",
  defaultProfilePhoto:
    "https://s3.us-east-2.amazonaws.com/liftrocket.org/astroFlag.jpg",
  defaultLiftPhoto:
    "https://s3.us-east-2.amazonaws.com/liftrocket.org/astroFlag.jpg",
};
export const googleTagEvent = {
  liftOwnerLanding: {
    event:'Lift_Owner_Landing',
    eventErrorLine: 'success',
    eventErrorLineType:'string',
  },
  boosterLanding: {
    event:'Booster_Landing',
    eventErrorLine: 'success',
    eventErrorLineType:'string',
  },
};